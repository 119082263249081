"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const firebase_1 = require("../shared/firebase");
class AuthStore {
    user;
    email = "";
    password = "";
    error = undefined;
    isLogin = true;
    onUserChange;
    // private rootStore: RootStore
    constructor(rootStore) {
        // this.rootStore = rootStore
        (0, mobx_1.makeAutoObservable)(this);
        console.log("FormStore constructor", rootStore);
        (0, firebase_1.onAuthStateChange)((user) => {
            this.user = user;
            if (this.onUserChange) {
                this.onUserChange(user);
            }
        });
    }
    // MARK: - Public
    observerUserChanges(onChange) {
        this.onUserChange = onChange;
    }
    setEmail(value) {
        this.email = value;
    }
    setPassword(value) {
        this.password = value;
    }
    async login() {
        try {
            await (0, firebase_1.signIn)(this.email, this.password);
        }
        catch (error) {
            if (typeof error === "string") {
                this.error = error;
            }
            else if (typeof error.message === "string") {
                this.error = error.message;
            }
            console.error("login error", error);
        }
    }
    async register() {
        try {
            await (0, firebase_1.signUp)(this.email, this.password);
        }
        catch (error) {
            if (typeof error === "string") {
                this.error = error;
            }
            else if (typeof error.message === "string") {
                this.error = error.message;
            }
            console.error("login error", error);
        }
    }
    async signOut() {
        try {
            await (0, firebase_1.logout)();
        }
        catch (error) {
            if (typeof error === "string") {
                this.error = error;
            }
            else if (typeof error.message === "string") {
                this.error = error.message;
            }
            console.error("login error", error);
        }
    }
}
__decorate([
    mobx_persist_1.persist,
    mobx_1.observable
], AuthStore.prototype, "user", void 0);
__decorate([
    mobx_1.observable
], AuthStore.prototype, "email", void 0);
__decorate([
    mobx_1.observable
], AuthStore.prototype, "password", void 0);
__decorate([
    mobx_1.observable
], AuthStore.prototype, "error", void 0);
__decorate([
    mobx_1.observable
], AuthStore.prototype, "isLogin", void 0);
exports.AuthStore = AuthStore;
