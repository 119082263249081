"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteWrapper = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const activity_page_1 = require("../modules/activity-page");
const clients_tab_1 = require("../modules/clients-tab");
const forms_tab_1 = require("../modules/forms-tab");
const request_page_1 = require("../modules/request-page");
const settings_page_1 = require("../modules/settings-page");
const router_1 = require("../shared/router");
const root_store_1 = require("../stores/root-store");
exports.RouteWrapper = (0, mobx_react_lite_1.observer)(() => {
    const navigate = (0, router_1.useNavigate)();
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const location = (0, router_1.useLocation)();
    rootStore.onRoute((route) => {
        console.log("RouteWrapper onRoute route", route);
        navigate(`/${route}`);
    });
    const newRoute = RouteUtils.mapRoute(location.pathname);
    const currentRoute = rootStore.menuStore.activeRoute;
    console.log("RouteWrapper new old", newRoute, currentRoute);
    if (currentRoute !== newRoute) {
        rootStore.menuStore.setItems(newRoute, false);
    }
    return (react_1.default.createElement(router_1.Routes, null,
        react_1.default.createElement(router_1.Route, { path: "/", element: react_1.default.createElement(activity_page_1.ActivityPage, null) }),
        react_1.default.createElement(router_1.Route, { path: "/activity", element: react_1.default.createElement(activity_page_1.ActivityPage, null) }),
        react_1.default.createElement(router_1.Route, { path: "/clients", element: react_1.default.createElement(clients_tab_1.ClientsTab, null) }),
        react_1.default.createElement(router_1.Route, { path: "/forms", element: react_1.default.createElement(forms_tab_1.FormsTab, null) }),
        react_1.default.createElement(router_1.Route, { path: "/settings", element: react_1.default.createElement(settings_page_1.SettingsPage, null) }),
        react_1.default.createElement(router_1.Route, { path: "/request", element: react_1.default.createElement(request_page_1.RequestPage, null) })));
});
class RouteUtils {
    static mapRoute(path) {
        if (path.charAt(0) === "/") {
            if (path.substring(1)) {
                return path.substring(1);
            }
            return "activity";
        }
        return path;
    }
}
