"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootStoreContext = exports.RootStore = void 0;
const mobx_persist_1 = require("mobx-persist");
const react_1 = require("react");
const react_native_1 = require("react-native");
const activity_store_1 = require("./activity-store");
const auth_store_1 = require("./auth-store");
const clients_store_1 = require("./clients-store");
const form_store_1 = require("./form-store");
const menu_store_1 = require("./menu-store");
const request_store_1 = require("./request-store");
const mobx_1 = require("mobx");
const hydrate = (0, mobx_persist_1.create)({
    storage: react_native_1.AsyncStorage,
    jsonify: true,
});
class RootStore {
    routeCallback;
    clientSubscriptions;
    formsSubscriptions;
    activitySubscriptions;
    menuStore = new menu_store_1.MenuStore(this);
    formStore = new form_store_1.FormStore(this);
    authStore = new auth_store_1.AuthStore(this);
    clientsStore = new clients_store_1.ClientsStore(this);
    requestStore = new request_store_1.RequestStore(this);
    activityStore = new activity_store_1.ActivityStore(this);
    userID;
    constructor() {
        hydrate("formStore", this.formStore);
        hydrate("clientsStore", this.clientsStore);
        hydrate("requestStore", this.requestStore);
        hydrate("activityStore", this.activityStore);
        this.authStore.observerUserChanges((user) => {
            this.unsubscribe();
            this.userID = undefined;
            if (user) {
                this.userID = user.uid;
                this.clientSubscriptions = this.clientsStore.setSortedClients();
                this.clientsStore.setRequests();
                this.formsSubscriptions = this.formStore.setForms();
                this.activitySubscriptions = this.activityStore.setActivities();
            }
        });
        (0, mobx_1.makeAutoObservable)(this);
    }
    routeTo(route) {
        if (this.routeCallback) {
            console.log("RootStore routeTo routeCallback exists", route);
            this.routeCallback(route);
        }
        else {
            console.error("Error in RootStore routeTo routeCallback undefined");
        }
    }
    onRoute(callback) {
        console.log("RootStore onRoute setter called");
        this.routeCallback = callback;
    }
    // MARK: - Private
    unsubscribe() {
        if (this.clientSubscriptions) {
            this.clientSubscriptions();
            this.clientSubscriptions = undefined;
        }
        if (this.formsSubscriptions) {
            this.formsSubscriptions();
            this.formsSubscriptions = undefined;
        }
        if (this.activitySubscriptions) {
            this.activitySubscriptions();
            this.activitySubscriptions = undefined;
        }
        this.requestStore.unsubscribe();
        this.formStore.unsubscribe();
        this.clientsStore.unsubscribe();
    }
}
__decorate([
    mobx_1.observable
], RootStore.prototype, "userID", void 0);
exports.RootStore = RootStore;
exports.RootStoreContext = (0, react_1.createContext)(new RootStore());
