"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityPage = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const react_native_1 = require("react-native");
const labels_1 = require("../components/labels");
const root_store_1 = require("../stores/root-store");
const h1_1 = require("../components/h1");
const page_container_1 = require("../components/page-container");
const activity_item_1 = require("../components/activity-item");
exports.ActivityPage = (0, mobx_react_lite_1.observer)(() => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const MappedItem = (activity) => {
        return react_1.default.createElement(activity_item_1.ActivityItem, { activity: activity });
    };
    const Header = react_1.default.createElement(h1_1.H1, { text: "Activity" });
    const MappedActivity = rootStore.activityStore.events.length < 1 ? (react_1.default.createElement(labels_1.NothingToShow, null)) : (rootStore.activityStore.events.map(MappedItem));
    const Content = (react_1.default.createElement(react_native_1.View, null,
        Header,
        MappedActivity));
    return react_1.default.createElement(page_container_1.ViewWithTabBar, { view: Content });
});
