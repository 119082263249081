"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormStore = exports.FormTypes = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
const utils_1 = require("../utils/utils");
function getNewQuestionOption() {
    return {
        label: "",
        uid: utils_1.Utils.getUUID(),
        isDeleted: false,
    };
}
exports.FormTypes = [
    {
        label: "Text",
        value: "text",
    },
    {
        label: "Select",
        value: "select",
    },
    {
        label: "Multiple Choice",
        value: "multipleChoice",
    },
];
class FormStore {
    forms = [];
    formOptions = [];
    form;
    questions = [];
    newOption = getNewQuestionOption();
    questionError;
    rootStore;
    questionsUnsubscriber = undefined;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("FormStore constructor", rootStore);
    }
    // MARK: - Public
    getName(formID) {
        if (!formID) {
            return undefined;
        }
        const index = this.forms.findIndex((form) => {
            return form.uid === formID;
        });
        if (index < 0) {
            return undefined;
        }
        return this.forms[index].name;
    }
    setForms() {
        const query = firestore_1.FirestorePath.Observe.forms(this.rootStore.userID);
        return (0, index_web_1.observeElements)(query.path, (forms) => {
            console.log("FormStore setForms observeElements callback", forms);
            this.forms = forms.filter((form) => {
                return !form.isDeleted;
            });
            this.setFormOptions();
        });
    }
    newForm() {
        const form = {
            uid: utils_1.Utils.getUUID(),
            name: "",
            isDeleted: false,
        };
        console.log("FormStore createForm", form);
        this.form = form;
        this.setQuestions(form);
    }
    unsetForm() {
        this.form = undefined;
    }
    setForm(form) {
        this.form = form;
        this.setQuestions(form);
    }
    async saveForm() {
        await this.saveQuestions();
        const query = firestore_1.FirestorePath.Store.form(this.rootStore.userID, this.form.uid);
        await (0, index_web_1.setElement)(query.path, this.form);
        this.form = undefined;
    }
    async deleteForm() {
        console.log("FormStore deleteForm");
        const query = firestore_1.FirestorePath.Store.form(this.rootStore.userID, this.form.uid);
        this.form.isDeleted = true;
        await (0, index_web_1.setElement)(query.path, this.form);
        this.form = undefined;
    }
    newQuestion() {
        const question = {
            dateOfCreation: utils_1.Utils.getDate(),
            index: this.questions.length,
            question: "",
            type: "text",
            uid: utils_1.Utils.getUUID(),
            options: {},
            isDeleted: false,
        };
        console.log("FormStore addQuestion", question);
        this.questions.push(question);
    }
    deleteOption(question, option) {
        const index = this.questions.findIndex((element) => {
            return element.uid === question.uid;
        });
        delete this.questions[index].options[question.type][option.uid];
    }
    addOption(question) {
        if (this.newOption.label.length < 1) {
            this.questionError = "Error adding Option. Need at least 1 character.";
            return;
        }
        this.questionError = undefined;
        const index = this.questions.findIndex((element) => {
            return element.uid === question.uid;
        });
        this.questions[index].options[question.type][this.newOption.uid] =
            this.newOption;
        this.newOption = getNewQuestionOption();
    }
    deleteQuestion(question) {
        const index = this.questions.findIndex((element) => {
            return element.uid === question.uid;
        });
        this.questions.splice(index, 1);
    }
    pushQuestion(question, up) {
        console.log("FormStore pushQuestion", question, up);
        const initialIndex = question.index;
        // Need to reverse up, so it makes sense on a UI level
        // We press the up button. But on the UI, that reduces the #.
        const finalIndex = !up ? initialIndex + 1 : initialIndex - 1;
        console.log("FormStore pushQuestion initialIndex", initialIndex, finalIndex);
        this.questions.splice(finalIndex, 0, this.questions.splice(initialIndex, 1)[0]);
        let newIndex = 0;
        for (const question of this.questions) {
            question.index = newIndex;
            newIndex += 1;
        }
        this.orderQuestions(this.questions);
    }
    setName(name) {
        if (this.form) {
            this.form.name = name;
        }
    }
    setNameFor(questionID, value) {
        const index = this.questions.findIndex((element) => {
            return element.uid === questionID;
        });
        this.questions[index].question = value;
    }
    setLabelForNewOption(value) {
        this.newOption.label = value;
    }
    setLabelForOption(questionID, questionType, optionID, value) {
        const index = this.questions.findIndex((element) => {
            return element.uid === questionID;
        });
        this.questions[index].options[questionType][optionID].label = value;
    }
    setTypeFor(questionID, value) {
        const index = this.questions.findIndex((element) => {
            return element.uid === questionID;
        });
        this.questions[index].type = value;
        if (value === "select" || value === "multipleChoice") {
            if (!this.questions[index].options) {
                this.questions[index].options = {};
            }
            if (!this.questions[index].options[value]) {
                this.questions[index].options[value] = {};
            }
        }
    }
    unsubscribe() {
        if (this.questionsUnsubscriber) {
            this.questionsUnsubscriber();
            this.questionsUnsubscriber = undefined;
        }
    }
    // MARK: - Private
    setQuestions(form) {
        this.questions = [];
        this.unsubscribe();
        const query = firestore_1.FirestorePath.Observe.questions(this.rootStore.userID, form.uid);
        this.questionsUnsubscriber = (0, index_web_1.observeElements)(query.path, (questions) => {
            console.log("FormStore setQuestions observeElements callback", questions);
            this.orderQuestions(questions);
        });
    }
    orderQuestions(questions) {
        console.log("FormStore orderQuestions", questions);
        this.questions = questions
            .filter((question) => {
            return !question.isDeleted;
        })
            .sort((question1, question2) => {
            return question1.index - question2.index;
        });
    }
    async saveQuestions() {
        for (const question of this.questions) {
            const query = firestore_1.FirestorePath.Store.question(this.rootStore.userID, this.form.uid, question.uid);
            await (0, index_web_1.setElement)(query.path, question);
        }
    }
    setFormOptions() {
        this.formOptions = [];
        if (this.forms && this.forms.map) {
            this.formOptions = this.forms.map((form) => {
                return {
                    label: form.name,
                    value: form.uid,
                };
            });
        }
    }
}
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], FormStore.prototype, "forms", void 0);
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], FormStore.prototype, "formOptions", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], FormStore.prototype, "form", void 0);
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], FormStore.prototype, "questions", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], FormStore.prototype, "newOption", void 0);
__decorate([
    mobx_persist_1.persist,
    mobx_1.observable
], FormStore.prototype, "questionError", void 0);
exports.FormStore = FormStore;
