"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsPage = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const form_item_1 = require("../components/form-item");
const list_header_1 = require("../components/list-header");
const page_container_1 = require("../components/page-container");
const root_store_1 = require("../stores/root-store");
exports.FormsPage = (0, mobx_react_lite_1.observer)(() => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const MappedForm = (form) => {
        return react_1.default.createElement(form_item_1.FormItem, { form: form });
    };
    const MappedContent = rootStore.formStore.forms.map(MappedForm);
    const Header = (react_1.default.createElement(list_header_1.ListHeader, { title: "Forms", onPress: () => rootStore.formStore.newForm() }));
    const Content = (react_1.default.createElement(react_native_1.View, null,
        Header,
        MappedContent));
    return react_1.default.createElement(page_container_1.ViewWithTabBar, { view: Content });
});
