"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestStore = void 0;
const mobx_1 = require("mobx");
const utils_1 = require("../utils/utils");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
class RequestStore {
    request;
    checkInInstances = {};
    rootStore;
    requestSubscription;
    checkInInstancesSubscriptions = {};
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("RequestStore constructor", rootStore);
    }
    observeRequests(clientID, onLoad) {
        this.unsubRequests();
        const query = firestore_1.FirestorePath.Observe.requests(this.rootStore.userID);
        console.log("RequestStore observeRequests", query);
        this.requestSubscription = (0, index_web_1.observeFilteredElements)(query.path, (0, index_web_1.REQUEST_QUERY)(clientID), async (requests) => {
            console.log("RequestStore observeRequests callback", requests);
            onLoad(requests);
            requests.forEach(request => {
                this.observeCheckInInstances(request);
            });
        });
    }
    getFormattedRequestDate(request) {
        const instances = this.checkInInstances[request.uid];
        if (!instances || instances.length < 1) {
            console.error("RequestStore getFormattedRequestDate no instances");
            return "-";
        }
        const date = instances[0].date;
        if (!date) {
            console.error("ClientsStore getFormattedRequestDate date invalid");
            return "-";
        }
        return `${new Date(date).toLocaleString()}`;
    }
    setRequest(request) {
        console.log("RequestStore setRequest", request);
        this.request = request;
        this.rootStore.routeTo("request");
    }
    newRequest() {
        const request = {
            uid: utils_1.Utils.getUUID(),
            clientID: this.rootStore.clientsStore.client.uid,
            isDeleted: false,
            formCadence: "monthly",
            formDate: new Date().toISOString(),
        };
        console.log("RequestStore newRequest", request);
        this.request = request;
        this.rootStore.routeTo("request");
    }
    setForm(formID) {
        if (this.request) {
            this.request.formID = formID;
        }
    }
    setCadence(cadence) {
        if (this.request) {
            this.request.formCadence = cadence;
            console.log("RequestStore setCadence", cadence);
        }
    }
    setDate(date) {
        if (this.request) {
            this.request.formDate = date.toISOString();
        }
    }
    async deleteRequest() {
        const query = firestore_1.FirestorePath.Store.request(this.rootStore.userID, this.request.uid);
        this.request.isDeleted = true;
        await (0, index_web_1.setElement)(query.path, this.request);
        this.doUnsetRequest();
    }
    async saveRequest() {
        if (this.request) {
            if (!this.request.formID) {
                this.request.formID = this.rootStore.formStore.forms[0].uid;
            }
        }
        const query = firestore_1.FirestorePath.Store.request(this.rootStore.userID, this.request.uid);
        await (0, index_web_1.setElement)(query.path, this.request);
        this.doUnsetRequest();
    }
    unsetRequest() {
        this.doUnsetRequest();
    }
    unsubscribe() {
        this.unsubRequests();
        this.ubsubCheckInInstances();
    }
    // MARK: - Private
    observeCheckInInstances(request) {
        this.ubsubCheckInInstances(request.uid);
        const query = firestore_1.FirestorePath.Observe.checkInInstances(request.uid);
        console.log("RequestStore observeCheckInInstances", query);
        this.checkInInstancesSubscriptions[request.uid] = (0, index_web_1.observeFilteredElements)(query.path, index_web_1.CHECKIN_QUERY_CONTRAINTS, (instances) => {
            console.log("RequestStore observeCheckInInstances callback", instances);
            this.checkInInstances[request.uid] = instances;
        });
    }
    unsubRequests() {
        if (this.requestSubscription) {
            this.requestSubscription();
        }
    }
    ubsubCheckInInstances(requestID) {
        // Unsub 1
        if (requestID && this.checkInInstancesSubscriptions[requestID]) {
            this.checkInInstancesSubscriptions[requestID]();
            delete this.checkInInstancesSubscriptions[requestID];
            return;
        }
        // Unsub all
        for (const requestID in Object.keys(this.checkInInstancesSubscriptions)) {
            this.checkInInstancesSubscriptions[requestID]();
            delete this.checkInInstancesSubscriptions[requestID];
        }
    }
    doUnsetRequest() {
        this.request = undefined;
        this.rootStore.routeTo("clients");
    }
}
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], RequestStore.prototype, "request", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], RequestStore.prototype, "checkInInstances", void 0);
exports.RequestStore = RequestStore;
