"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = void 0;
exports.environment = {
    firebase: {
        apiKey: "AIzaSyAKDgjXAs-F3fT-24VPGN1we5mIyF-RdIE",
        authDomain: "checkinly-2cae0.firebaseapp.com",
        projectId: "checkinly-2cae0",
        storageBucket: "checkinly-2cae0.appspot.com",
        messagingSenderId: "63036724640",
        appId: "1:63036724640:web:751f8c1049832e1bd900c5",
        measurementId: "G-57JDBV1WP0",
    },
};
