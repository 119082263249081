"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPage = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const action_button_1 = require("../components/action-button");
const edit_header_1 = require("../components/edit-header");
const labels_1 = require("../components/labels");
const h1_1 = require("../components/h1");
const input_1 = require("../components/input");
const input_header_1 = require("../components/input-header");
const root_store_1 = require("../stores/root-store");
const global_props_1 = require("../utils/global-props");
const request_card_1 = require("../components/request-card");
const page_container_1 = require("../components/page-container");
exports.ClientPage = (0, mobx_react_lite_1.observer)(() => {
    const rootStore = (0, react_1.useContext)(root_store_1.RootStoreContext);
    const NameSection = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(input_header_1.InputHeader, { title: "Name" }),
        react_1.default.createElement(input_1.Input, { value: rootStore.clientsStore.client.name, placeholder: "Unnamed Client", onChange: (text) => rootStore.clientsStore.setName(text) })));
    const EmailSection = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(input_header_1.InputHeader, { title: "Email" }),
        react_1.default.createElement(input_1.Input, { value: rootStore.clientsStore.client.email, placeholder: "client@email.com", onChange: (text) => rootStore.clientsStore.setEmail(text) })));
    const ActionsSection = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(h1_1.H1, { text: "Actions" }),
        react_1.default.createElement(action_button_1.DeleteButton, { onPress: () => rootStore.clientsStore.deleteClient() })));
    const DetailsSection = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(h1_1.H1, { text: "Client Details" }),
        react_1.default.createElement(react_native_1.View, { style: global_props_1.GlobalStyle.contentBox },
            NameSection,
            EmailSection)));
    const CheckInRequests = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(react_native_1.View, { style: global_props_1.GlobalStyle.headerBar },
            react_1.default.createElement(h1_1.H1, { text: "Check-In Requests" }),
            react_1.default.createElement(action_button_1.AddButton, { onPress: () => rootStore.requestStore.newRequest() })),
        rootStore.clientsStore.requests.length < 1 ? (react_1.default.createElement(labels_1.NothingToShow, null)) : (rootStore.clientsStore.requests.map((request) => {
            return react_1.default.createElement(request_card_1.RequestCard, { key: request.uid, request: request });
        }))));
    const CheckInHistory = (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(h1_1.H1, { text: "Check-In History" }),
        react_1.default.createElement(labels_1.NothingToShow, null)));
    const Header = (react_1.default.createElement(edit_header_1.EditHeader, { onBack: () => rootStore.clientsStore.unsetClient(), onSave: () => rootStore.clientsStore.saveClient() }));
    const Content = (react_1.default.createElement(react_native_1.View, null,
        Header,
        DetailsSection,
        CheckInRequests,
        CheckInHistory,
        ActionsSection));
    return react_1.default.createElement(page_container_1.ViewWithoutTabBar, { view: Content });
});
