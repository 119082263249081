"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirestorePath = void 0;
class FirestorePath {
    // To upload
    static Store = new (class {
        request(userID, requestID) {
            return {
                path: ["CheckInRequest", userID, "CheckInRequest", requestID],
            };
        }
        client(userID, clientID) {
            return {
                path: ["Clients", userID, "Clients", clientID],
            };
        }
        question(userID, formID, questionID) {
            return {
                path: [
                    "Questions",
                    userID,
                    "Questions",
                    formID,
                    "Questions",
                    questionID,
                ],
            };
        }
        form(userID, formID) {
            return {
                path: ["Forms", userID, "Forms", formID],
            };
        }
    })();
    // To download
    static Observe = new (class {
        client(userID, clientID) {
            return {
                path: ["Clients", userID, "Clients", clientID],
            };
        }
        requests(userID) {
            return {
                path: ["CheckInRequest", userID, "CheckInRequest"],
            };
        }
        checkInInstances(requestID) {
            return {
                path: ["FutureCheckinRequests", requestID, "Instances"],
            };
        }
        sortedClients(userID) {
            return {
                path: ["SortedClients", userID],
            };
        }
        recentClients(userID) {
            return {
                path: ["RecentClients", userID],
            };
        }
        forms(userID) {
            return {
                path: ["Forms", userID, "Forms"],
            };
        }
        questions(userID, formID) {
            return {
                path: ["Questions", userID, "Questions", formID, "Questions"],
            };
        }
        activity(userID) {
            return {
                path: ["Activity", userID, "Activity"],
            };
        }
    })();
}
exports.FirestorePath = FirestorePath;
