"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckInCadenceOptions = void 0;
exports.CheckInCadenceOptions = [
    {
        label: "Daily",
        value: "daily",
    },
    {
        label: "Weekly",
        value: "weekly",
    },
    {
        label: "Monthly",
        value: "monthly",
    },
];
