"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientsStore = void 0;
const mobx_1 = require("mobx");
const mobx_persist_1 = require("mobx-persist");
const index_web_1 = require("../shared/firebase/index.web");
const firestore_1 = require("../types/firestore");
const utils_1 = require("../utils/utils");
class ClientsStore {
    client;
    requests = [];
    sortedClients = {};
    clientIDSubscription;
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        (0, mobx_1.makeAutoObservable)(this);
        console.log("ClientsStore constructor", rootStore);
    }
    setRequests() {
        console.log("ClientsStore setRequests", this.client);
        if (this.client) {
            this.rootStore.requestStore.observeRequests(this.client.uid, (requests) => {
                console.log("ClientsStore setRequests callback", requests, this.client.uid);
                this.requests = requests;
            });
        }
    }
    setSortedClients() {
        const query = firestore_1.FirestorePath.Observe.sortedClients(this.rootStore.userID);
        console.log("ClientsStore setSortedClients", query);
        return (0, index_web_1.observeElement)(query.path, (sortedClients) => {
            console.log("ClientsStore setSortedClients callback", sortedClients);
            this.sortedClients = sortedClients;
        });
    }
    newClient() {
        this.requests = [];
        this.client = {
            uid: utils_1.Utils.getUUID(),
            name: "",
            email: "",
            isDeleted: false,
            date: utils_1.Utils.getDate(),
        };
    }
    setClient(client) {
        this.requests = [];
        this.client = client;
        this.setRequests();
    }
    setClientID(clientID) {
        console.log("ClientsStore onRoute setClientID", clientID);
        if (!clientID) {
            return;
        }
        this.unsubscribeClientID();
        const query = firestore_1.FirestorePath.Observe.client(this.rootStore.userID, clientID);
        console.log("ClientsStore setClientID", query);
        this.clientIDSubscription = (0, index_web_1.observeElement)(query.path, (client) => {
            console.log("ClientsStore setSortedClients callback", client);
            this.setClient(client);
            this.rootStore.routeTo("clients");
        });
    }
    unsetClient() {
        this.client = undefined;
        this.rootStore.routeTo("clients");
    }
    setName(name) {
        if (this.client) {
            this.client.name = name;
        }
    }
    setEmail(email) {
        if (this.client) {
            this.client.email = email;
        }
    }
    async saveClient() {
        const query = firestore_1.FirestorePath.Store.client(this.rootStore.userID, this.client.uid);
        await (0, index_web_1.setElement)(query.path, this.client);
        this.client = undefined;
    }
    async deleteClient() {
        this.client.isDeleted = true;
        const query = firestore_1.FirestorePath.Store.client(this.rootStore.userID, this.client.uid);
        await (0, index_web_1.setElement)(query.path, this.client);
        this.client = undefined;
    }
    unsubscribe() {
        this.unsubscribeClientID();
    }
    // MARK: - Private
    unsubscribeClientID() {
        if (this.clientIDSubscription) {
            this.clientIDSubscription();
            this.clientIDSubscription = undefined;
        }
    }
}
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], ClientsStore.prototype, "client", void 0);
__decorate([
    (0, mobx_persist_1.persist)("list"),
    mobx_1.observable
], ClientsStore.prototype, "requests", void 0);
__decorate([
    (0, mobx_persist_1.persist)("object"),
    mobx_1.observable
], ClientsStore.prototype, "sortedClients", void 0);
exports.ClientsStore = ClientsStore;
