"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setElement = exports.observeFilteredElements = exports.observeElements = exports.observeElement = exports.REQUEST_QUERY = exports.CHECKIN_QUERY_CONTRAINTS = exports.logout = exports.signIn = exports.signUp = exports.onAuthStateChange = void 0;
// Import the functions you need from the SDKs you need
const app_1 = require("firebase/app");
const analytics_1 = require("firebase/analytics");
const firestore_1 = require("firebase/firestore");
const environment_1 = require("../../utils/environment");
const auth_1 = require("firebase/auth");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Initialize Firebase
const app = (0, app_1.initializeApp)(environment_1.environment.firebase);
const analytics = (0, analytics_1.getAnalytics)(app);
const auth = (0, auth_1.getAuth)(app);
const firestore = (0, firestore_1.getFirestore)();
console.log("analytics", analytics);
async function onAuthStateChange(onChange) {
    console.log("onAuthStateChange");
    (0, auth_1.onAuthStateChanged)(auth, (user) => {
        if (user) {
            onChange({
                uid: user.uid,
                email: user.email ?? undefined,
            });
        }
        else {
            onChange(undefined);
        }
    });
}
exports.onAuthStateChange = onAuthStateChange;
async function signUp(email, password) {
    try {
        const userCredentials = await (0, auth_1.createUserWithEmailAndPassword)(auth, email, password);
        return {
            uid: userCredentials.user.uid,
            email: userCredentials.user.email ?? undefined,
        };
    }
    catch (error) {
        throw error;
    }
}
exports.signUp = signUp;
async function signIn(email, password) {
    try {
        const userCredentials = await (0, auth_1.signInWithEmailAndPassword)(auth, email, password);
        return {
            uid: userCredentials.user.uid,
            email: userCredentials.user.email ?? undefined,
        };
    }
    catch (error) {
        throw error;
    }
}
exports.signIn = signIn;
async function logout() {
    try {
        await (0, auth_1.signOut)(auth);
    }
    catch (error) {
        throw error;
    }
}
exports.logout = logout;
const firestore_2 = require("firebase/firestore");
function getDocumentObserver(path, onLoad) {
    const firstElement = path.shift();
    const reference = (0, firestore_2.doc)(firestore, firstElement, ...path);
    return (0, firestore_2.onSnapshot)(reference, (doc) => {
        const result = doc.data();
        console.log("getSnapshotObserver onSnapshot callback", result);
        onLoad(result);
    });
}
exports.CHECKIN_QUERY_CONTRAINTS = [
    (0, firestore_1.where)("isArchived", "==", false),
    (0, firestore_1.where)("isProcessed", "==", false),
    (0, firestore_1.orderBy)("index", "asc"),
    (0, firestore_1.limit)(1)
];
const REQUEST_QUERY = (clientID) => {
    return [
        (0, firestore_1.where)("isDeleted", "==", false),
        (0, firestore_1.where)("clientID", "==", clientID)
    ];
};
exports.REQUEST_QUERY = REQUEST_QUERY;
function getDocumentsObserver(path, queryConstraints, onLoad) {
    const firstElement = path.shift();
    const reference = (0, firestore_1.collection)(firestore, firstElement, ...path);
    return (0, firestore_2.onSnapshot)((0, firestore_1.query)(reference, ...queryConstraints), (docs) => {
        let result = [];
        docs.forEach((doc) => {
            result.push(doc.data());
        });
        console.log("getDocumentsObserver onSnapshot callback", result);
        onLoad(result);
    });
}
function observeElement(path, onLoad) {
    return getDocumentObserver(path, (data) => {
        onLoad(data);
    });
}
exports.observeElement = observeElement;
function observeElements(path, onLoad) {
    return getDocumentsObserver(path, [], (data) => {
        onLoad(data);
    });
}
exports.observeElements = observeElements;
function observeFilteredElements(path, filter, onLoad) {
    return getDocumentsObserver(path, filter, (data) => {
        onLoad(data);
    });
}
exports.observeFilteredElements = observeFilteredElements;
async function setElement(path, element) {
    const firstElement = path.shift();
    const reference = (0, firestore_2.doc)(firestore, firstElement, ...path);
    await (0, firestore_1.setDoc)(reference, element);
}
exports.setElement = setElement;
